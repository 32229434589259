//Variables
$font-size-base             : 1.6rem;
$font-weight-base           : 400;
$line-height-base           : 1.3;

$ff-sans-serif              : "Bebas Neue", sans-serif;
$ff-serif                   : Georgia, "Times New Roman", Times, serif;

$t-duration                 : 0.4s;

$shell-max-width            : 114rem;
$shell-gutter               : 1.5rem;

$c-black                    : #000;
$c-white                    : #fff;
$c-base                     : #055590;

$responsive-ios             : 812px;

$breakpoints: (
	mobile-small: 374px,
	mobile-custom: 544px,
	mobile-medium: 640px,
	mobile: 767px,
	admin-bar: 782px,
	tablet: 1023px,
	small-desktop: 1199px,
	large-desktop: 1399px
);
