/* ------------------------------------------------------------ *\
  Vendor
\* ------------------------------------------------------------ */

//@import '~vendor-name/vendor.scss/css';

/* ------------------------------------------------------------ *\
	Generic
\* ------------------------------------------------------------ */

@import 'generic/variables';
@import 'generic/functions';
@import 'generic/mixins';
@import 'generic/reset';
@import 'generic/fonts';
@import 'generic/base';
@import 'generic/helpers';

/* ------------------------------------------------------------ *\
	Components
\* ------------------------------------------------------------ */

@import '_sprite.css';
@import "components/_area.scss";
@import "components/_btn.scss";
@import "components/_cards.scss";
@import "components/_checkbox.scss";
@import "components/_field.scss";
@import "components/_footer.scss";
@import "components/_form.scss";
@import "components/_goTop.scss";
@import "components/_grid.scss";
@import "components/_header.scss";
@import "components/_hero.scss";
@import "components/_icons.scss";
@import "components/_label.scss";
@import "components/_logo.scss";
@import "components/_main.scss";
@import "components/_map.scss";
@import "components/_nav.scss";
@import "components/_radio.scss";
@import "components/_scroll.scss";
@import "components/_section-about.scss";
@import "components/_section-admin.scss";
@import "components/_section-api.scss";
@import "components/_section-awards.scss";
@import "components/_section-team.scss";
@import "components/_section.scss";
@import "components/_select.scss";
@import "components/_shell.scss";
@import "components/_socials.scss";
@import "components/_textarea.scss";
@import "components/_wrapper.scss";

/* ------------------------------------------------------------ *\
	Overwrites
\* ------------------------------------------------------------ */

//@import 'overwrites/';