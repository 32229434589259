/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 10;
	background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.85) 47%, rgba(0,0,0,0.74) 60%, rgba(0,0,0,0.6) 70%, rgba(0,0,0,0) 100%);

	.header__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 10rem;
	}

	.header__ham {
		display: none;
		position: absolute;
		top: 50%;
		right: 3rem;
		transform: translateY(-50%);
		height: 4rem;
		width: 4rem;
		transition: transform 0.3s;
		
		.header__hamIn {
			height: 1rem;
			opacity: 0;
			position: absolute;
			top: 50%;
			width: 4rem;
			transform: translateY(-50%);
			background-color: #fff;
			border-radius: 0.3rem;
			transition: opacity 0.3s;
		}

		&:after,
		&:before {
			content: "";
			background-color: #fff;
			display: block;
			position: absolute;
			left: 0;
			transition: height 0.3s;
		}

		&:before {
			top: 0;
			height: 1.7rem;
			width: 4rem;
			border-bottom-left-radius: 0.3rem;
			border-bottom-right-radius: 0.3rem;
			border-top-left-radius: 1rem;
			border-top-right-radius: 1rem;
		}

		&:after {
			bottom: 0;
			height: 1.7rem;
			width: 4rem;
			border-top-left-radius: 0.3rem;
			border-top-right-radius: 0.3rem;
			border-bottom-left-radius: 1rem;
			border-bottom-right-radius: 1rem;
		}

		@include breakpoint-down(mobile) {
			display: block;
		}
	}

	.header__ham--open {
		transform: translateY(-50%) rotate(90deg);

		.header__hamIn {
			opacity: 1;
		}

		&:before {
			top: 0;
			height: 1rem;
		}

		&:after {
			bottom: 0;
			height: 1rem;
		}
	}
}
