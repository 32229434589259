/* ------------------------------------------------------------ *\
	section-team
\* ------------------------------------------------------------ */

.section-team {
	height: 100vh;
	padding: 12rem 0 0;
	position: relative;

	@include breakpoint-down(tablet) {
		height: auto;
	}

	.section__head {
		margin-bottom: 2rem;

		@include breakpoint-down(mobile-custom) {
			margin-bottom: 1rem;	
		}
	}

	.section__memImg {
		height: 20rem;
		width: 100%;
		margin-bottom: 1.5rem;

		@include breakpoint-down(tablet) {
			margin-bottom: 0;
			position: relative;
			height: 40rem;

			&:after {
				content: "";
				background-color: rgba(0, 0, 0, 0.6);
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		@include breakpoint-down(mobile) {
			height: 30rem;
		}

		@include breakpoint-down(mobile-medium) {
			height: 25rem;
		}
	}

	.section__memImg img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	ul.section__team {
		list-style: none;
		display: flex;
		justify-content: space-between;
		column-gap: 2rem;
		width: calc(100% - 10rem);

		@include breakpoint-down(tablet) {
			column-gap: 0;
			width: 100%;
			flex-wrap: wrap;
		}
	}

	.section__member {
		text-align: center;
		flex: 0 0 calc(100% / 6);

		@include breakpoint-down(tablet) {
			position: relative;
			flex: 0 0 calc(100% / 3);
		}

		@include breakpoint-down(mobile-custom) {
			flex: 0 0 calc(100% / 2);
		}
	}

	.section__memInf {
		@include breakpoint-down(tablet) {
			bottom: 2.5rem;
			left: 50%;
			transform: translateX(-50%);
			position: absolute;
			z-index: 3;
			width: 100%;
		}

		@include breakpoint-down(mobile-medium) {
			bottom: 1rem;
		}
	}

	.section__memInf h3 {
		margin-bottom: 0;

		@include breakpoint-down(tablet) {
			font-size: 2.6rem;
		}

		@include breakpoint-down(mobile) {
			font-size: 2rem;
		}

		@include breakpoint-down(mobile-medium) {
			line-height: 1;
		}
	}

	.section__head h2 {
		font-size: 5.5rem;

		@include breakpoint-down(mobile-custom) {
			font-size: 4rem;
		}
	}
}