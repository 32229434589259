/* ------------------------------------------------------------ *\
	section-api
\* ------------------------------------------------------------ */

.section-api {
	height: 100vh;
	padding: 12rem 0 10rem;
	position: relative;

	.section__head h2 {
		font-size: 5.5rem;
	}

	.section__body {
		margin-top: 2rem;
	}

	.section__row {
		display: flex;
		width: 100%;
	}

	.section__input {
		width: 35rem;
		position: relative;
	}

	.section__iArea {
		position: absolute;
		top: 50%;
		right: 2rem;
		cursor: pointer;
		transform: translateY(-50%);
		font-size: 1.8rem;
	}

	.section__actions {
		margin-left: 2.5rem;
	}

	.section__options {
		margin-top: 3rem;
	}

	.section__check + .section__check {
		margin-top: 1rem;
	}

	.section__check label {
		padding-left: 3.5rem;
		position: relative;
		line-height: 1;

		&:before {
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			height: 2rem;
			width: 2rem;
			background-color: #000;
			border: 0.2rem solid #fff;
			border-radius: 0.6rem;
		}

		&:after {
			content: "";
			position: absolute;
			opacity: 0;
			top: 50%;
			left: 0.7rem;
			transform: translateY(calc(-50% - 0.2rem)) rotate(45deg);
			height: 0.8rem;
			width: 0.6rem;
			border-style: solid;
			border-width: 0.2rem;
			border-color: transparent #fff #fff transparent;
			transition: opacity 0.3s;
		}
	}

	.checkbox:checked + label:after {
		opacity: 1;
	}
}