/* ------------------------------------------------------------ *\
	section-about
\* ------------------------------------------------------------ */

.section-about {
	height: 100vh;
	padding: 12rem 0 0;
	position: relative;

	.section__content {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		@include breakpoint-down(mobile-custom) {
			font-size: 1.4rem;
		}
	}

	.section__head h2 {
		font-size: 5.5rem;

		@include breakpoint-down(mobile-custom) {
			font-size: 4rem;
		}
	}

	.section__body p {
		font-family: Arial;
	}

	@include breakpoint-down(tablet) {
		.grid__col--1of2:nth-child(2) {
			display: none;
		}

		.grid__col--1of2 {
			flex: 0 0 75%;
			max-width: 75%;
		}
	}

	@include breakpoint-down(mobile) {
		.grid__col--1of2 {
			flex: 0 0 85%;
			max-width: 85%;
		}
	}

	@include breakpoint-down(mobile-medium) {
		.grid__col--1of2 {
			flex: 0 0 95%;
			max-width: 95%;
		}
	}
	
	.section__img {

	}

	.section__img img {
		width: 80%;
		height: auto;
		object-fit: contain;
	}
}