/* ------------------------------------------------------------ *\
	area
\* ------------------------------------------------------------ */

.area {
	border: 0.2rem solid #fff;
	padding: 1.5rem 2.5rem;
	font-size: 2rem;
	border-radius: 3rem;
	background-color: transparent;
	width: 100%;
}