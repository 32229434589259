/* ------------------------------------------------------------ *\
	Checkbox
\* ------------------------------------------------------------ */

.checkbox {
	opacity: 0;
	position: absolute;
	top: 0;
	pointer-events: none;
}
