/* ------------------------------------------------------------ *\
	section-awards
\* ------------------------------------------------------------ */

.section-awards {
	height: 100vh;
	padding: 12rem 0 0;

	.section__head {
		margin-bottom: 2rem;
	}

	.section__head h2 {
		font-size: 5.5rem;

		@include breakpoint-down(mobile-custom) {
			font-size: 4rem;
		}
	}

	.section__body ul {
		list-style: none;
	}

	.section__body li {
		display: flex;
		align-items: center;
	}

	.section__desc small {
		margin-bottom: 0.8rem;
		display: block;
	}

	.section__desc i {
		padding-right: 0.6rem;
	}

	.section__img {
		height: 9rem;
		width: 9rem;
		margin-right: 3rem;

		@include breakpoint-down(mobile-custom) {
			height: 7rem;
			width: 7rem;
			margin-right: 1.5rem;
		}
	}

	.section__img img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	.section__hac {
		border-radius: 50%;
		overflow: hidden;
	}

	.section__desc h3 {
		font-size: 3rem;
		margin-bottom: 0;

		@include breakpoint-down(mobile-custom) {
			font-size: 2rem;
		}
	}

	.section__desc ul li {
		display: inline-block;

		@include breakpoint-down(mobile-custom) {
			display: block;
		}
	}

	.section__desc ul li + li {
		margin-left: 2rem;

		@include breakpoint-down(mobile-custom) {
			margin-left: 0rem;
		}
	}
}