/* ------------------------------------------------------------ *\
	Scroll
\* ------------------------------------------------------------ */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #11131c; 
}

::-webkit-scrollbar-thumb {
  background: #444857; 
}

::-webkit-scrollbar-thumb:hover {
  background: #4f5366; 
}