/* ------------------------------------------------------------ *\
  Shell
\* ------------------------------------------------------------ */

.shell {
	max-width: calc( #{$shell-max-width} + #{$shell-gutter} * 2 );
	padding-left: $shell-gutter;
	padding-right: $shell-gutter;
	margin: auto;
	width: 100%;
	padding: 0 1.5rem;

	@include breakpoint-down(tablet) {
		padding: 0 2.5rem;
	}
}

/* Shell Fluid */

.shell--fluid {
	max-width: none;
}
