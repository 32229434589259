/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
	display:block;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	appearance: none;
	text-transform: uppercase;
	background-color: transparent;
	border: 0.2rem solid #fff;
	padding: 1.5rem 2.5rem;
	font-size: 2rem;
	font-family: $ff-sans-serif;
	font-weight: 700;
	position: relative;


	border-radius: 3rem;
	display: flex;
	align-items: center;

	.btn__icon {
		margin-left: 0;
		font-size: 0;
		transition: margin-left 0.3s, font-size 0.3s;
	}

	&:hover .btn__icon {
		margin-left: 2rem;
		font-size: 1.8rem;
	}
}

/*  Btn block  */

.btn--block {
	display: block;
	padding-left: 0;
	padding-right: 0;
}