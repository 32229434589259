/* ------------------------------------------------------------ *\
  Vendor
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Generic
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
  SCSS Functions
\* ------------------------------------------------------------ */
/* example usage*/
/* ------------------------------------------------------------ *\
	Reset
\* ------------------------------------------------------------ */
@import '_sprite.css';
* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main {
  display: block;
}

template {
  display: none;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: none;
}

img,
iframe,
video,
audio,
object {
  max-width: 100%;
}

img,
iframe {
  border: 0 none;
}

img {
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

b,
strong {
  font-weight: bold;
}

address {
  font-style: normal;
}

svg:not(:root) {
  overflow: hidden;
}

a,
button,
input[type=submit],
input[type=button],
input[type=reset],
input[type=file],
input[type=image],
label[for] {
  cursor: pointer;
}

a[href^=tel],
button[disabled],
input[disabled],
textarea[disabled],
select[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input[type=text],
input[type=password],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=month],
input[type=week],
input[type=email],
input[type=number],
input[type=search],
input[type=tel],
input[type=time],
input[type=url],
input[type=color],
textarea,
a[href^=tel] {
  appearance: none;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  appearance: none;
}

textarea {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

button,
select {
  text-transform: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

nav ul,
nav ol {
  list-style: none outside none;
}

input::placeholder {
  color: inherit;
  opacity: 1;
}

textarea::placeholder {
  color: inherit;
  opacity: 1;
}

input:-webkit-autofill {
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

input[type=text],
input[type=button],
input[type=tel],
input[type=email],
input[type=submit],
input[type=date],
input[type=search],
textarea {
  appearance: none;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
/* ------------------------------------------------------------ *\
    Fonts
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */
html {
  font-size: 62.5%;
}

body {
  min-width: 320px;
  background: #00050E;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.3;
  color: #fff;
}

a {
  color: inherit;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}
a[href^=tel] {
  text-decoration: none;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  margin-bottom: calc(1.3em / 2);
}

p,
ul,
ol,
dl,
hr,
table,
blockquote {
  margin-bottom: 1.3em;
}

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
  margin-bottom: 0;
}

/* ------------------------------------------------------------ *\
  Helpers
\* ------------------------------------------------------------ */
/*  Clear  */
.clear:after {
  content: "";
  line-height: 0;
  display: table;
  clear: both;
}

/* sr-only */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*  Notext  */
.notext {
  white-space: nowrap;
  text-indent: 100%;
  text-shadow: none;
  overflow: hidden;
}

.notext-old {
  font-size: 0;
  line-height: 0;
  text-indent: -4000px;
}

/*  Hidden  */
[hidden],
.hidden {
  display: none;
}

/*  Disabled  */
[disabled],
.disabled {
  cursor: default;
}

/*  Responsive Helpers  */
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hidden-xs {
    display: none !important;
  }
  .visible-xs-block {
    display: block !important;
  }
  .visible-xs-inline {
    display: inline !important;
  }
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 767.02px) and (max-width: 1023px) {
  .hidden-sm {
    display: none !important;
  }
  .visible-sm-block {
    display: block !important;
  }
  .visible-sm-inline {
    display: inline !important;
  }
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1023.02px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
  .visible-md-block {
    display: block !important;
  }
  .visible-md-inline {
    display: inline !important;
  }
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1199.02px) {
  .hidden-lg {
    display: none !important;
  }
  .visible-lg-block {
    display: block !important;
  }
  .visible-lg-inline {
    display: inline !important;
  }
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
/* ------------------------------------------------------------ *\
	Components
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	area
\* ------------------------------------------------------------ */
.area {
  border: 0.2rem solid #fff;
  padding: 1.5rem 2.5rem;
  font-size: 2rem;
  border-radius: 3rem;
  background-color: transparent;
  width: 100%;
}

/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */
.btn {
  display: block;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  appearance: none;
  text-transform: uppercase;
  background-color: transparent;
  border: 0.2rem solid #fff;
  padding: 1.5rem 2.5rem;
  font-size: 2rem;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 700;
  position: relative;
  border-radius: 3rem;
  display: flex;
  align-items: center;
}
.btn .btn__icon {
  margin-left: 0;
  font-size: 0;
  transition: margin-left 0.3s, font-size 0.3s;
}
.btn:hover .btn__icon {
  margin-left: 2rem;
  font-size: 1.8rem;
}

/*  Btn block  */
.btn--block {
  display: block;
  padding-left: 0;
  padding-right: 0;
}

/* ------------------------------------------------------------ *\
	Cards
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Checkbox
\* ------------------------------------------------------------ */
.checkbox {
  opacity: 0;
  position: absolute;
  top: 0;
  pointer-events: none;
}

/* ------------------------------------------------------------ *\
	Fields
\* ------------------------------------------------------------ */
/*  Field textarea  */
/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	form
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	goTop
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Grid
\* ------------------------------------------------------------ */
.grid {
  display: flex;
  flex-flow: row wrap;
  /*  Grid col 1of2  */
  /*  Grid col 1of3  */
}
.grid .grid__col {
  max-width: 100%;
  flex: 1;
}
.grid .grid__col--1of2 {
  max-width: 50%;
  flex: 0 0 50%;
}
.grid .grid__col--1of3 {
  max-width: 33.3333333333%;
  flex: 0 0 33.3333333333%;
}

/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 47%, rgba(0, 0, 0, 0.74) 60%, rgba(0, 0, 0, 0.6) 70%, rgba(0, 0, 0, 0) 100%);
}
.header .header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10rem;
}
.header .header__ham {
  display: none;
  position: absolute;
  top: 50%;
  right: 3rem;
  transform: translateY(-50%);
  height: 4rem;
  width: 4rem;
  transition: transform 0.3s;
}
.header .header__ham .header__hamIn {
  height: 1rem;
  opacity: 0;
  position: absolute;
  top: 50%;
  width: 4rem;
  transform: translateY(-50%);
  background-color: #fff;
  border-radius: 0.3rem;
  transition: opacity 0.3s;
}
.header .header__ham:after, .header .header__ham:before {
  content: "";
  background-color: #fff;
  display: block;
  position: absolute;
  left: 0;
  transition: height 0.3s;
}
.header .header__ham:before {
  top: 0;
  height: 1.7rem;
  width: 4rem;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.header .header__ham:after {
  bottom: 0;
  height: 1.7rem;
  width: 4rem;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .header .header__ham {
    display: block;
  }
}
.header .header__ham--open {
  transform: translateY(-50%) rotate(90deg);
}
.header .header__ham--open .header__hamIn {
  opacity: 1;
}
.header .header__ham--open:before {
  top: 0;
  height: 1rem;
}
.header .header__ham--open:after {
  bottom: 0;
  height: 1rem;
}

/* ------------------------------------------------------------ *\
	hero
\* ------------------------------------------------------------ */
.hero {
  height: 100vh;
  position: relative;
}
.hero .hero__inner {
  position: relative;
  height: 100vh;
}
.hero .hero__head {
  position: relative;
}
.hero .hero__actions {
  margin-top: 2rem;
  opacity: 1;
  transition: opacity 1s;
}
.hero .hero__content {
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: calc(114rem + 1.5rem * 2);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: auto;
  width: 100%;
  padding: 0 1.5rem;
  transform: translate(-50%, calc(-50% + 1rem));
  z-index: 4;
  transition: transform 1s, top 1s, left 1s;
}
@media (max-width: 1023px) {
  .hero .hero__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.hero .hero__content--scroll {
  top: 0rem;
  left: 50%;
  padding-left: 8rem;
  height: 10rem;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 12;
}
.hero .hero__content--scroll .hero__actions {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.hero .hero__content--scroll .hero__head h1 {
  font-size: 4.3rem;
}
.hero .hero__content--scroll .hero__head small {
  font-size: 1.8rem;
}
@media (max-width: 1023px) {
  .hero .hero__content--scroll {
    padding-left: 9rem;
  }
  .hero .hero__content--scroll .hero__head h1 {
    text-align: left;
  }
  .hero .hero__content--scroll .hero__head small {
    text-align: left;
  }
}
@media (max-width: 544px) {
  .hero .hero__content--scroll .hero__head h1 {
    font-size: 3rem;
  }
  .hero .hero__content--scroll .hero__head small {
    font-size: 1.4rem;
  }
}
@media (max-width: 374px) {
  .hero .hero__content--scroll .hero__head h1 {
    font-size: 2.6rem;
  }
  .hero .hero__content--scroll .hero__head small {
    font-size: 1.3rem;
  }
}
.hero .hero__head h1 {
  margin-bottom: 0;
  font-size: 9rem;
  line-height: 1;
}
@media (max-width: 640px) {
  .hero .hero__head h1 {
    font-size: 6.5rem;
  }
}
@media (max-width: 544px) {
  .hero .hero__head h1 {
    font-size: 5rem;
  }
}
.hero .hero__head small {
  font-size: 2.85rem;
  line-height: 1;
}
@media (max-width: 1023px) {
  .hero .hero__head small {
    width: 100%;
    display: block;
    text-align: center;
  }
}
@media (max-width: 544px) {
  .hero .hero__head small {
    font-size: 2rem;
  }
}
.hero .hero__bg {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
}
.hero .hero__bg:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  top: 0;
  left: 0;
}
.hero .hero__bg video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.hero .hero__img {
  position: absolute;
  top: 50%;
  right: 7rem;
  transform: translateY(-50%);
  width: 30rem;
  animation: moveUpDown 5s infinite;
  z-index: 3;
  filter: brightness(65%);
}
@media (max-width: 1023px) {
  .hero .hero__img {
    display: none;
  }
}
@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(calc(-50% + 3rem));
  }
  50% {
    transform: translateY(calc(-50% + 5rem));
  }
}
.hero .hero__img img {
  height: auto;
  width: 100%;
  object-fit: contain;
}
/* ------------------------------------------------------------ *\
	Icons
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	label
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */
.logo {
  height: 5.5rem;
  width: 5rem;
}
.logo img {
  height: 100%;
  width: auto;
  object-fit: contain;
}

/* ------------------------------------------------------------ *\
	Main
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	map
\* ------------------------------------------------------------ */
.map {
  height: 100vh;
  color: #000;
}
.map .mapboxgl-popup {
  padding-bottom: 2.3rem;
}
.map .mapboxgl-popup-content {
  padding: 1.4rem 3.5rem 1.4rem 1.5rem;
  font-family: Arial;
  position: relative;
}
.map .mapboxgl-popup-close-button {
  font-size: 0;
  position: absolute;
  height: 1.2rem;
  width: 1.2rem;
  top: 1.2rem;
  right: 1rem;
}
.map .mapboxgl-popup-close-button:after, .map .mapboxgl-popup-close-button:before {
  content: "";
  position: absolute;
  background-color: #000;
  height: 100%;
  width: 0.1rem;
  top: 50%;
  left: 50%;
}
.map .mapboxgl-popup-close-button:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.map .mapboxgl-popup-close-button:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.map p {
  margin-bottom: 0;
}
.map h3 {
  font-size: 1.7rem;
  line-height: 1;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: flex-end;
  padding-left: 0.3rem;
}
.map span {
  padding-left: 0.2rem;
}
.map span, .map a {
  font-weight: 700;
}
.map .map__icon {
  width: 3rem;
  text-align: center;
  font-size: 1.6rem;
  padding-top: 0.3rem;
}
.map .map__flag {
  width: 3rem;
  text-align: center;
  font-size: 2.5rem;
  margin-right: 0.2rem;
}
.map .map__flag--red {
  color: #b81414;
}
.map .map__flag--yellow {
  color: #fcba03;
}
.map .map__flag--green {
  color: #107811;
}

/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .nav {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    height: 100vh;
    top: 0;
    right: 0;
    padding: 10rem 0 0;
    background: linear-gradient(270deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 47%, rgba(0, 0, 0, 0.74) 60%, rgba(0, 0, 0, 0.6) 76%, rgba(0, 0, 0, 0) 100%);
    width: 13rem;
    transition: opacity 0.3s;
  }
}
.nav ul {
  list-style: none;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .nav ul {
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    width: 6.2rem;
    text-align: right;
  }
}
.nav ul a {
  text-decoration: none;
  font-size: 2.2rem;
  position: relative;
  padding: 0;
}
.nav ul a:after {
  content: "";
  position: absolute;
  bottom: 0.1rem;
  left: 0;
  width: 100%;
  background-color: #fff;
  height: 0.25rem;
  transition: 0.3s width;
}
.nav ul a:hover:after {
  width: 0%;
}
.nav ul li {
  display: inline-block;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .nav ul li {
    display: block;
  }
}
.nav ul li + li {
  margin-left: 2rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .nav ul li + li {
    margin-left: 0;
    margin-top: 2rem;
  }
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .nav--open {
    opacity: 1;
    pointer-events: auto;
  }
}

/* ------------------------------------------------------------ *\
	Radio
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Scroll
\* ------------------------------------------------------------ */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #11131c;
}

::-webkit-scrollbar-thumb {
  background: #444857;
}

::-webkit-scrollbar-thumb:hover {
  background: #4f5366;
}

/* ------------------------------------------------------------ *\
	section-about
\* ------------------------------------------------------------ */
.section-about {
  height: 100vh;
  padding: 12rem 0 0;
  position: relative;
}
.section-about .section__content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 544px) {
  .section-about .section__content {
    font-size: 1.4rem;
  }
}
.section-about .section__head h2 {
  font-size: 5.5rem;
}
@media (max-width: 544px) {
  .section-about .section__head h2 {
    font-size: 4rem;
  }
}
.section-about .section__body p {
  font-family: Arial;
}
@media (max-width: 1023px) {
  .section-about .grid__col--1of2:nth-child(2) {
    display: none;
  }
  .section-about .grid__col--1of2 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-about .grid__col--1of2 {
    flex: 0 0 85%;
    max-width: 85%;
  }
}
@media (max-width: 640px) {
  .section-about .grid__col--1of2 {
    flex: 0 0 95%;
    max-width: 95%;
  }
}
.section-about .section__img img {
  width: 80%;
  height: auto;
  object-fit: contain;
}

/* ------------------------------------------------------------ *\
	section-admin
\* ------------------------------------------------------------ */
.section-admin {
  padding: 12rem 0 8rem;
  position: relative;
}
.section-admin .section__head h2 {
  font-size: 5.5rem;
}
.section-admin .section__body {
  margin-top: 2rem;
}
.section-admin .section__switch {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  width: 30rem;
  height: 6rem;
  border: 0.2rem solid #fff;
  border-radius: 3rem;
  display: flex;
  justify-content: center;
}
.section-admin .section__switch:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 0.2rem;
  background-color: #fff;
  transform: translate(-50%, -50%);
}
.section-admin .section__sPart {
  height: calc(100% - 0.4rem);
  position: absolute;
  top: 0;
  width: calc(50% - 0.5rem);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;
}
.section-admin .section__sBuoy {
  left: 0;
  color: #000;
  background-color: #fff;
  margin: 0.2rem 0 0 0.2rem;
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem;
}
.section-admin .section__sStat {
  left: 50%;
  margin: 0.2rem 0 0 0.3rem;
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 3rem;
}
.section-admin .section__head .checkbox:checked + .section__switch .section__sBuoy {
  color: #fff;
  background-color: #000;
}
.section-admin .section__head .checkbox:checked + .section__switch .section__sStat {
  color: #000;
  background-color: #fff;
}
.section-admin .section__body {
  margin-top: 2rem;
}
.section-admin .section__row + .section__row {
  margin-top: 1.5rem;
}
.section-admin .section__open {
  display: none;
  position: absolute;
  top: 50%;
  right: 4.5rem;
  height: 2rem;
  width: 2rem;
  border-style: solid;
  border-width: 0.2rem;
  border-color: transparent #fff #fff transparent;
  transform: translateY(-75%) rotate(45deg);
  cursor: pointer;
}
.section-admin .section__data {
  flex: 1;
}
.section-admin .section__buoy .section__row {
  position: relative;
  padding: 2.5rem 9rem 2.5rem 3rem;
  border: 0.2rem solid #fff;
  border-radius: 8rem;
  display: flex;
}
.section-admin .section__buoy .section__head {
  flex: 0 0 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section-admin .section__buoy .section__img {
  height: 6rem;
  text-align: center;
  width: 6rem;
}
.section-admin .section__buoy .section__img img {
  height: 100%;
  width: auto;
  object-fit: contain;
}
.section-admin .section__buoy .section__head h3 {
  margin-bottom: 0;
}
.section-admin .section__buoy .section__space {
  margin-right: 0.7rem;
}
.section-admin .section__bdata {
  color: #ffb405;
}
.section-admin .section__stat {
  display: none;
}
.section-admin .section__stat .section__row {
  position: relative;
  padding: 2.5rem 3rem;
  border: 0.2rem solid #fff;
  border-radius: 8rem;
  display: flex;
}
.section-admin .section__actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1rem;
}
.section-admin .section__rem,
.section-admin .section__inf {
  min-width: 10rem;
  padding: 0.8rem 1.5rem;
  font-size: 1.8rem;
  text-align: center;
  border-radius: 2.2rem;
  border: none;
}
.section-admin .section__rem {
  border: 0.2rem solid #fff;
  background-color: transparent;
  cursor: pointer;
}
.section-admin .section__inf {
  border: 0.2rem solid #fff;
  overflow: hidden;
  position: relative;
}
.section-admin .section__inf .section__infBG {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  width: 70%;
  border-radius: 3rem;
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 1rem, rgba(0, 0, 0, 0.2) 1rem, rgba(0, 0, 0, 0.2) 2rem);
  background-size: 200% 200%;
  animation: barberpole 10s linear infinite;
}
.section-admin .section__infOK {
  background-color: #437a0d;
}
.section-admin .section__infWarn {
  background-color: #fca832;
}
.section-admin .section__infBad {
  background-color: #ab0c26;
}
@keyframes barberpole {
  100% {
    background-position: 100% 100%;
  }
}

/* ------------------------------------------------------------ *\
	section-api
\* ------------------------------------------------------------ */
.section-api {
  height: 100vh;
  padding: 12rem 0 10rem;
  position: relative;
}
.section-api .section__head h2 {
  font-size: 5.5rem;
}
.section-api .section__body {
  margin-top: 2rem;
}
.section-api .section__row {
  display: flex;
  width: 100%;
}
.section-api .section__input {
  width: 35rem;
  position: relative;
}
.section-api .section__iArea {
  position: absolute;
  top: 50%;
  right: 2rem;
  cursor: pointer;
  transform: translateY(-50%);
  font-size: 1.8rem;
}
.section-api .section__actions {
  margin-left: 2.5rem;
}
.section-api .section__options {
  margin-top: 3rem;
}
.section-api .section__check + .section__check {
  margin-top: 1rem;
}
.section-api .section__check label {
  padding-left: 3.5rem;
  position: relative;
  line-height: 1;
}
.section-api .section__check label:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 2rem;
  width: 2rem;
  background-color: #000;
  border: 0.2rem solid #fff;
  border-radius: 0.6rem;
}
.section-api .section__check label:after {
  content: "";
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 0.7rem;
  transform: translateY(calc(-50% - 0.2rem)) rotate(45deg);
  height: 0.8rem;
  width: 0.6rem;
  border-style: solid;
  border-width: 0.2rem;
  border-color: transparent #fff #fff transparent;
  transition: opacity 0.3s;
}
.section-api .checkbox:checked + label:after {
  opacity: 1;
}

/* ------------------------------------------------------------ *\
	section-awards
\* ------------------------------------------------------------ */
.section-awards {
  height: 100vh;
  padding: 12rem 0 0;
}
.section-awards .section__head {
  margin-bottom: 2rem;
}
.section-awards .section__head h2 {
  font-size: 5.5rem;
}
@media (max-width: 544px) {
  .section-awards .section__head h2 {
    font-size: 4rem;
  }
}
.section-awards .section__body ul {
  list-style: none;
}
.section-awards .section__body li {
  display: flex;
  align-items: center;
}
.section-awards .section__desc small {
  margin-bottom: 0.8rem;
  display: block;
}
.section-awards .section__desc i {
  padding-right: 0.6rem;
}
.section-awards .section__img {
  height: 9rem;
  width: 9rem;
  margin-right: 3rem;
}
@media (max-width: 544px) {
  .section-awards .section__img {
    height: 7rem;
    width: 7rem;
    margin-right: 1.5rem;
  }
}
.section-awards .section__img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.section-awards .section__hac {
  border-radius: 50%;
  overflow: hidden;
}
.section-awards .section__desc h3 {
  font-size: 3rem;
  margin-bottom: 0;
}
@media (max-width: 544px) {
  .section-awards .section__desc h3 {
    font-size: 2rem;
  }
}
.section-awards .section__desc ul li {
  display: inline-block;
}
@media (max-width: 544px) {
  .section-awards .section__desc ul li {
    display: block;
  }
}
.section-awards .section__desc ul li + li {
  margin-left: 2rem;
}
@media (max-width: 544px) {
  .section-awards .section__desc ul li + li {
    margin-left: 0rem;
  }
}

/* ------------------------------------------------------------ *\
	section-team
\* ------------------------------------------------------------ */
.section-team {
  height: 100vh;
  padding: 12rem 0 0;
  position: relative;
}
@media (max-width: 1023px) {
  .section-team {
    height: auto;
  }
}
.section-team .section__head {
  margin-bottom: 2rem;
}
@media (max-width: 544px) {
  .section-team .section__head {
    margin-bottom: 1rem;
  }
}
.section-team .section__memImg {
  height: 20rem;
  width: 100%;
  margin-bottom: 1.5rem;
}
@media (max-width: 1023px) {
  .section-team .section__memImg {
    margin-bottom: 0;
    position: relative;
    height: 40rem;
  }
  .section-team .section__memImg:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-team .section__memImg {
    height: 30rem;
  }
}
@media (max-width: 640px) {
  .section-team .section__memImg {
    height: 25rem;
  }
}
.section-team .section__memImg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.section-team ul.section__team {
  list-style: none;
  display: flex;
  justify-content: space-between;
  column-gap: 2rem;
  width: calc(100% - 10rem);
}
@media (max-width: 1023px) {
  .section-team ul.section__team {
    column-gap: 0;
    width: 100%;
    flex-wrap: wrap;
  }
}
.section-team .section__member {
  text-align: center;
  flex: 0 0 16.6666666667%;
}
@media (max-width: 1023px) {
  .section-team .section__member {
    position: relative;
    flex: 0 0 33.3333333333%;
  }
}
@media (max-width: 544px) {
  .section-team .section__member {
    flex: 0 0 50%;
  }
}
@media (max-width: 1023px) {
  .section-team .section__memInf {
    bottom: 2.5rem;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 3;
    width: 100%;
  }
}
@media (max-width: 640px) {
  .section-team .section__memInf {
    bottom: 1rem;
  }
}
.section-team .section__memInf h3 {
  margin-bottom: 0;
}
@media (max-width: 1023px) {
  .section-team .section__memInf h3 {
    font-size: 2.6rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-team .section__memInf h3 {
    font-size: 2rem;
  }
}
@media (max-width: 640px) {
  .section-team .section__memInf h3 {
    line-height: 1;
  }
}
.section-team .section__head h2 {
  font-size: 5.5rem;
}
@media (max-width: 544px) {
  .section-team .section__head h2 {
    font-size: 4rem;
  }
}

/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Select
\* ------------------------------------------------------------ */
.select select::-ms-expand {
  display: none;
}

/* ------------------------------------------------------------ *\
  Shell
\* ------------------------------------------------------------ */
.shell {
  max-width: calc(114rem + 1.5rem * 2);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: auto;
  width: 100%;
  padding: 0 1.5rem;
}
@media (max-width: 1023px) {
  .shell {
    padding: 0 2.5rem;
  }
}

/* Shell Fluid */
.shell--fluid {
  max-width: none;
}

/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	textarea
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */
.wrapper {
  position: relative;
  min-height: 100vh;
}

/* ------------------------------------------------------------ *\
	Overwrites
\* ------------------------------------------------------------ */