/* ------------------------------------------------------------ *\
	map
\* ------------------------------------------------------------ */

.map {
	height: 100vh;
	color: #000;

	.mapboxgl-popup {
		padding-bottom: 2.3rem;
	}

	.mapboxgl-popup-content {
		padding: 1.4rem 3.5rem 1.4rem 1.5rem;
		font-family: Arial;
		position: relative;
	}

	.mapboxgl-popup-close-button {
		font-size: 0;
		position: absolute;
		height: 1.2rem;
		width: 1.2rem;
		top: 1.2rem;
		right: 1rem;

		&:after,
		&:before {
			content: "";
			position: absolute;
			background-color: #000;
			height: 100%;
			width: 0.1rem;
			top: 50%;
			left: 50%;
		}

		&:after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		&:before {
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}

	p {
		margin-bottom: 0;
	}

	h3 {
		font-size: 1.7rem;
		line-height: 1;
		margin-bottom: 0.8rem;
		display: flex;
		align-items: flex-end;
		padding-left: 0.3rem;
	}

	span {
		padding-left: 0.2rem;
	}

	span, a {
		font-weight: 700;
	}

	.map__icon {
		width: 3rem;
		text-align: center;
		font-size: 1.6rem;
		padding-top: 0.3rem;
	}

	.map__flag {
		width: 3rem;
		text-align: center;
		font-size: 2.5rem;
		margin-right: 0.2rem;
	}

	.map__flag--red {
		color: #b81414;
	}

	.map__flag--yellow {
		color: #fcba03;
	}

	.map__flag--green {
		color: #107811;
	}
}