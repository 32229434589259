/* ------------------------------------------------------------ *\
	Reset
\* ------------------------------------------------------------ */

* {
	padding: 0;
	margin: 0;
	outline: 0;
	box-sizing: border-box;
}

*:before,
*:after {
	box-sizing: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main {
	display: block;
}

template {
	display: none;
}

html {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	tap-highlight-color: rgba(0,0,0,0);
}

body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	text-size-adjust: none;
}

img,
iframe,
video,
audio,
object {
	max-width: 100%;
}

img,
iframe {
	border: 0 none;
}

img {
	height: auto;
	display: inline-block;
	vertical-align: middle;
}

b,
strong {
	font-weight: bold;
}

address {
	font-style: normal;
}

svg:not(:root) {
	overflow: hidden;
}

a,
button,
input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="image"],
label[for] {
	cursor: pointer;
}

a[href^="tel"],
button[disabled],
input[disabled],
textarea[disabled],
select[disabled] {
	cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	padding: 0;
	border: 0;
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea,
a[href^="tel"] {
	appearance: none;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	appearance: none;
}

textarea {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
}

button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}

button,
select {
	text-transform: none;
}

table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

nav ul,
nav ol {
	list-style: none outside none;
}

input::placeholder {
	color: inherit;
	opacity: 1;
}
textarea::placeholder {
	color: inherit;
	opacity: 1;
}

input:-webkit-autofill {
	-webkit-text-fill-color: inherit;
	-webkit-box-shadow: 0 0 0 1000px #fff inset;
}

input[type=text],
input[type=button],
input[type=tel],
input[type=email],
input[type=submit],
input[type=date],
input[type=search],
textarea {
	appearance: none;
}

@media (prefers-reduced-motion: no-preference) {
	:root {
		scroll-behavior: smooth;
	}
}
