/* ------------------------------------------------------------ *\
	section-admin
\* ------------------------------------------------------------ */

.section-admin {
	padding: 12rem 0 8rem;
	position: relative;

	.section__head h2 {
		font-size: 5.5rem;
	}

	.section__body {
		margin-top: 2rem;
	}

	.section__switch {
		overflow: hidden;
		position: relative;
		margin: 0 auto;
		width: 30rem;
		height: 6rem;
		border: 0.2rem solid #fff;
		border-radius: 3rem;
		display: flex;
		justify-content: center;

		&:after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			height: 100%;
			width: 0.2rem;
			background-color: #fff;
			transform: translate(-50%, -50%);
		}
	}

	.section__sPart {
		height: calc(100% - 0.4rem);
		position: absolute;
		top: 0;
		width: calc(50% - 0.5rem);
		display: flex;
		justify-content: center;
		align-items: center; 
		cursor: pointer;
		transition: color 0.3s, background-color 0.3s;
	}

	.section__sBuoy {
		left: 0;
		color: #000;
		background-color: #fff;
		margin: 0.2rem 0 0 0.2rem;
		border-top-left-radius: 3rem;
		border-bottom-left-radius: 3rem;
	}

	.section__sStat {
		left: 50%;
		margin: 0.2rem 0 0 0.3rem;
		border-top-right-radius: 3rem;
		border-bottom-right-radius: 3rem;
	}

	.section__head {
		.checkbox:checked + .section__switch .section__sBuoy {
			color: #fff;
			background-color: #000;
		}

		.checkbox:checked + .section__switch .section__sStat {
			color: #000;
			background-color: #fff;
		}
	}

	.section__body {
		margin-top: 2rem;
	}

	.section__row + .section__row {
		margin-top: 1.5rem;
	}

	.section__open {
		display: none;
		position: absolute;
		top: 50%;
		right: 4.5rem;
		height: 2rem;
		width: 2rem;
		border-style: solid;
		border-width: 0.2rem;
		border-color: transparent #fff #fff transparent;
		transform: translateY(-75%) rotate(45deg);
		cursor: pointer;
	}

	.section__data {
		flex: 1;
	}

	.section__buoy {
		.section__row {
			position: relative;
			padding: 2.5rem 9rem 2.5rem 3rem;
			border: 0.2rem solid #fff;
			border-radius: 8rem;
			display: flex;
		}

		.section__head {
			flex: 0 0 10rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		.section__img {
			height: 6rem;
			text-align: center;
			width: 6rem;
		}

		.section__img img {
			height: 100%;
			width: auto;
			object-fit: contain;
		}

		.section__head h3 {
			margin-bottom: 0;
		}

		.section__space {
			margin-right: 0.7rem;
		}
	}

	.section__bdata {
		color: #ffb405;
	}

	.section__stat {
		display: none;

		.section__row {
			position: relative;
			padding: 2.5rem 3rem;
			border: 0.2rem solid #fff;
			border-radius: 8rem;
			display: flex;
		}
	}

	.section__actions {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		row-gap: 1rem;
	}

	.section__rem,
	.section__inf {
		min-width: 10rem;
		padding: 0.8rem 1.5rem;
		font-size: 1.8rem;
		text-align: center;
		border-radius: 2.2rem;
		border: none;
	}

	.section__rem {
		border: 0.2rem solid #fff;
		background-color: transparent;
		cursor: pointer;
	}

	.section__inf {
		border: 0.2rem solid #fff;
		overflow: hidden;
		position: relative;
		

		.section__infBG {
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			height: 100%;
			width: 70%;
			border-radius: 3rem;
			background-image: 
			    repeating-linear-gradient(
					-45deg, 
					transparent, 
					transparent 1rem,
					rgba(0, 0, 0, 0.2) 1rem,
					rgba(0, 0, 0, 0.2) 2rem
			    );
			    
			background-size: 200% 200%;
			animation: barberpole 10s linear infinite;
		}	
	}

	.section__infOK {
		background-color: #437a0d;
	}

	.section__infWarn {
		background-color: #fca832;
	}

	.section__infBad {
		background-color: #ab0c26;
	}

	@keyframes barberpole {
		100% {
		  	background-position: 100% 100%;
		}
	}
}