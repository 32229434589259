/* ------------------------------------------------------------ *\
	hero
\* ------------------------------------------------------------ */

.hero {
	height: 100vh;
	position: relative;

	.hero__inner {
		position: relative;
		height: 100vh;
	}

	.hero__head {
		position: relative;
	}

	.hero__actions {
		margin-top: 2rem;
		opacity: 1;
		transition: opacity 1s;
	}

	.hero__content {
		position: fixed;
		top: 50%;
		left: 50%;
		max-width: calc( #{$shell-max-width} + #{$shell-gutter} * 2 );
		padding-left: $shell-gutter;
		padding-right: $shell-gutter;
		margin: auto;
		width: 100%;
		padding: 0 1.5rem;
		transform: translate(-50%, calc(-50% + 1rem));
		z-index: 4;
		transition: transform 1s, top 1s, left 1s;

		@include breakpoint-down(tablet) {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	.hero__content--scroll {
		top: 0rem;
		left: 50%;
		padding-left: 8rem;
		height: 10rem;
		transform: translate(-50%, 0%);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		z-index: 12;

		.hero__actions {
			position: absolute;
			opacity: 0;
			pointer-events: none;
		}

		.hero__head h1 {
			font-size: 4.3rem;
		}

		.hero__head small {
			font-size: 1.8rem;
		}

		@include breakpoint-down(tablet) {
			padding-left: 9rem;

			.hero__head h1 {
				text-align: left;
			}

			.hero__head small {
				text-align: left;
			}
		}

		@include breakpoint-down(mobile-custom) {
			.hero__head h1 {
				font-size: 3rem;
			}

			.hero__head small {
				font-size: 1.4rem;
			}
		}

		@include breakpoint-down(mobile-small) {
			.hero__head h1 {
				font-size: 2.6rem;
			}

			.hero__head small {
				font-size: 1.3rem;
			}
		}
	}

	.hero__head h1 {
		margin-bottom: 0;
		font-size: 9rem;
		line-height: 1;

		@include breakpoint-down(mobile-medium) {
			font-size: 6.5rem;
		}

		@include breakpoint-down(mobile-custom) {
			font-size: 5rem;
		}
	}

	.hero__head small {
		font-size: 2.85rem;
		line-height: 1;

		@include breakpoint-down(tablet) {
			width: 100%;
			display: block;
			text-align: center;
		}

		@include breakpoint-down(mobile-custom) {
			font-size: 2rem;
		}
	}

	.hero__bg {
		position: absolute;
		height: 100vh;
		width: 100%;
		top: 0;
		left: 0;

		&:after {
			content: "";
			position: absolute;
			height: 100%;
			width: 100%;
			background-color: rgba(0,0,0, 0.8);
			z-index: 2;
			top: 0;
			left: 0;
		}
	}

	.hero__bg video {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	.hero__img {
		position: absolute;
		top: 50%;
		right: 7rem;
		transform: translateY(-50%);
		width: 30rem;
		animation: moveUpDown 5s infinite;
		z-index: 3;
		filter: brightness(65%);

		@include breakpoint-down(tablet) {
			display: none;
		}
	}

	@keyframes moveUpDown {
		0%, 100% {
			transform: translateY(calc(-50% + 3rem));  
		}

		50% {
			transform: translateY(calc(-50% + 5rem));  
		}
	}

	.hero__img img {
		height: auto;
		width: 100%;
		object-fit: contain;
	}

	.hero__transition {
		
	}
}