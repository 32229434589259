/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
	@include breakpoint-down(mobile) {
		opacity: 0;
		pointer-events: none;
		position: absolute;
		height: 100vh;
		top: 0;
		right: 0;
		padding: 10rem 0 0;
		background: linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.85) 47%, rgba(0,0,0,0.74) 60%, rgba(0,0,0,0.6) 76%, rgba(0,0,0,0) 100%);
		width: 13rem;
		transition: opacity 0.3s;
	}

	ul {
		list-style: none;

		@include breakpoint-down(mobile) {
			position: absolute;
			top: 50%;
			right: 2rem; 
			transform: translateY(-50%);
			width: 6.2rem;
			text-align: right;
		}
	}

	ul a {
		text-decoration: none;
		font-size: 2.2rem;
		position: relative;
		padding: 0;

		&:after {
			content: "";
			position: absolute;
			bottom: 0.1rem;
			left: 0;
			width: 100%;
			background-color: #fff;
			height: 0.25rem;
			transition: 0.3s width;
		}

		&:hover:after {
			width: 0%;
		}
	}

	ul li {
		display: inline-block;

		@include breakpoint-down(mobile) {
			display: block;
		}
	}

	ul li + li {
		margin-left: 2rem;

		@include breakpoint-down(mobile) {
			margin-left: 0;
			margin-top: 2rem;
		}
	}
}

.nav--open {
	@include breakpoint-down(mobile) {
		opacity: 1;
		pointer-events: auto;
	}
}
