/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
	height: 5.5rem;
	width: 5rem;
	
	img {
		height: 100%;
		width: auto;
		object-fit: contain;
	}
}
